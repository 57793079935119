import { InjectionToken } from '@angular/core';
import { CustomerApiService } from './customer-api.service';

export const HTTP_CUSTOMER_SERVICE = new InjectionToken<CustomerApiService>(
  'CustomerApiService'
);

export const CUSTOMER_PROVIDER = {
  provide: HTTP_CUSTOMER_SERVICE,
  useClass: CustomerApiService,
};
