import { inject, Injectable } from '@angular/core';
import { ICustomerApiService } from './customer-api.interface';
import { map, Observable, take } from 'rxjs';
import { Customer, CustomerCreateOrUpdate } from '../domain/customer.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerApiModel } from './customer-api.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerApiService implements ICustomerApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapResponseToCustomerModel(response: CustomerApiModel): Customer {
    return {
      customerId: response.customerId,
      uid: response.uid,
      name: response.name,
      phone: response.phone,
      email: response.email,
      notifications: response.notifications,
      companyId: response.companyId,
      statusId: response.statusId,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    };
  }

  getCustomers(filter?: string): Observable<Customer[]> {
    const url = `${this.BASE_URL}customers?q=${filter || ''}`;
    const headers = new HttpHeaders();
    return this._http.get<CustomerApiModel[]>(url, { headers }).pipe(
      take(1),
      map((response) => response.map(this._mapResponseToCustomerModel))
    );
  }

  createCustomer(customer: CustomerCreateOrUpdate): Observable<Customer> {
    const url = `${this.BASE_URL}customers`;
    const headers = new HttpHeaders();
    return this._http
      .post<CustomerApiModel>(url, customer, { headers })
      .pipe(take(1), map(this._mapResponseToCustomerModel));
  }

  updateCustomer(
    uuid: string,
    customer: CustomerCreateOrUpdate
  ): Observable<Customer> {
    const url = `${this.BASE_URL}customers/${uuid}`;
    const headers = new HttpHeaders();
    return this._http
      .put<CustomerApiModel>(url, customer, { headers })
      .pipe(take(1), map(this._mapResponseToCustomerModel));
  }

  deleteCustomer(uuid: string): Observable<void> {
    const url = `${this.BASE_URL}customers/${uuid}`;
    const headers = new HttpHeaders();
    return this._http.delete<void>(url, { headers });
  }
}
